.min-h-screen {
    min-height: var(--screenfull) !important;
}

.max-h-screen {
    max-height: var(--screenfull) !important;
}

.h-screen {
    height: var(--screenfull) !important;
}

.h-insidescreen {
    height: calc(var(--screenfull) - 1rem) !important;
}

.min-h-insidescreen {
    min-height: calc(var(--screenfull) - 1rem) !important;
}

.max-h-insidescreen {
    max-height: calc(var(--screenfull) - 1rem) !important;
}

.background-lines {
    background: repeating-linear-gradient(
        45deg,
        #039be5 0px,
        #039be5 20px,
        #90caf9 20px,
        #90caf9 40px
    );
}
